<template>
  <section class='section'>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1>Comentarios</h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="control">
            <button
              class="button"
              type="button"
              :class="{ 'is-loading': isLoading }"
              @click="getCsvDownload"
            >
              Descargar
            </button>
          </div>
        </div>
        <div class="level-item field">
          <div class="control">
            <input class="input" type="text" v-model="search" @input="searchComments" placeholder="Buscador...">
          </div>
        </div>
      </div>
    </nav>
    <div class="columns is-multiline">
      <div class="column is-12">
        <base-table
          class="is-bordered is-striped is-narrow is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="commmentList"
          :loading=loading
        >
        </base-table>
      </div>
    </div>
    <base-pagination
      class="is-medium"
      :page="page"
      :pages="parseInt(pages)"
      :action="paginator"
      :loading="loading"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import mixin from '../../mixin/validateDate'

export default {
  mixins: [mixin],
  name: 'Transacciones',
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination')
  },
  data () {
    return {
      addUserModal: false,
      modalEditAdmin: false,
      page: 1,
      pages: '',
      loading: false,
      isLoading: false,
      search: '',
      commmentList: [],
      columnsData: [
        {
          id: 'id', header: '#', class: 'column-mobile'
        },
        {
          id: 'productName',
          header: 'Producto',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `productos/detalle/${row.productId}`
        },
        {
          id: 'username',
          header: 'Usuario',
          class: 'column-mobile',
          type: 'link',
          link: true,
          route: (row) => `usuarios/detalle/${row.userId}`
        },
        {
          id: 'text',
          header: 'Mensaje',
          class: 'column-mobile'
        },
        { id: 'response', header: 'Respuesta', class: 'column-mobile' },
        { id: 'createdAt', header: 'Fecha', class: 'column-mobile', accessor: (row) => this.formatDateTimestamp(row.createdAt) }
      ]
    }
  },
  methods: {
    ...mapActions(['getComments', 'csvDownloadComments', 'addTemporalNotification']),
    paginator (page) {
      this.page = page
      this.loading = true
      this.getComments({ page, search: this.search })
        .then(resp => {
          this.loading = false
          this.commmentList = resp.payload
          this.pages = resp.totalPages
        })
    },
    searchComments ({ target }) {
      if (target.value.length >= 3 || target.value.length === 0) {
        this.search = target.value
        this.getComments({ page: this.page, search: target.value })
          .then(resp => {
            this.commmentList = resp.payload
            this.pages = resp.totalPages
            this.loading = false
          })
      }
    },
    getCsvDownload () {
      this.isLoading = true
      this.csvDownloadComments({ search: this.search })
        .then(resp => {
          if (resp) {
            const exportName = 'Export comments.csv'
            const blob = new Blob([resp], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, exportName)
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', exportName)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
          } else {
            this.addTemporalNotification({ type: 'danger', message: 'Error en la descarga' })
          }
          this.isLoading = false
        })
    }
  },
  beforeMount () {
    this.loading = true
    this.getComments({ page: this.page, search: this.search })
      .then(resp => {
        this.commmentList = resp.payload
        this.pages = resp.totalPages
        this.loading = false
      })
  }
}
</script>
<style scoped>
  .section {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .icon-action {
    cursor: pointer;
    color: #039DA1;
  }
  .table.is-narrow td, .table.is-narrow th {
    max-width: 230px;
  }
</style>
